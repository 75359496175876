
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "e16388d2-3f16-429c-9b98-d6910c4bc8f4"
  },
  "_MAPBOX_CONFIG": {
    "accessToken": "pk.eyJ1IjoibWFwLWxvdmVyLXJvYmluIiwiYSI6ImNsbjBjeG1vNzFhdHcya256cXlrbTZocTYifQ.7Q-haGYCotdA3mafNSWZqQ"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
